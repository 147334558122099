import { DAppProvider, useEtherBalance, useEthers } from '@usedapp/core';
import { BigNumber, ethers } from 'ethers';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { BrowserRouter as Router, useHistory, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Decimal from 'decimal.js';
import { UniV3PoolAbi } from './UniV3PoolAbi';
import { FlashbotsBundleProvider } from '@flashbots/ethers-provider-bundle';

const signer = ethers.Wallet.createRandom();

const ACTIVE = '#eede73';

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
`

const AddressBookColumn = styled.div`
  width: calc(50% - 16px);
  height: calc(100% - 16px);
  max-height: calc(100% - 16px);
  border: 2px solid white;
  margin: 6px;
`

const ABIColumn = styled.div`
  width: calc(50% - 16px);
  height: calc(100% - 16px);
  border: 2px solid white;
  margin: 6px;
`

const MethodColumn = styled.div`
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  border: 2px solid white;
  margin: 6px;
`

const TxParametersContainer = styled.div`
  width: calc(50% - 54px);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ConfigurationRow = styled.div`
  width: calc(100% - 16px);
  height: calc(70% - 16px);
  border: 2px solid white;
  margin: 6px
`

const FlashNow = styled.div<{ soft: boolean; flash: boolean }>`

  height: calc(10% - 16px);
  width: calc(100% - 16px);
  border: 2px solid ${props => props.soft ? ACTIVE : (props.flash ? 'black' : ACTIVE)};
  background-color: ${props => props.soft ? 'black' : (props.flash ? ACTIVE : 'black')};
  color: ${props => props.soft ? ACTIVE : (props.flash ? 'black' : ACTIVE)};
  margin: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;


  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

  &:active {
  background-color: ${ACTIVE};
  border: 2px solid black;
  color: black;
  }

`;

const ConsoleRow = styled.div`
  width: calc(100% - 16px);
  height: calc(20% - 16px);
  border: 2px solid white;
  margin: 6px
`

const ConnectColumn = styled.div<{ inverted: boolean }>`
  writing-mode: vertical-rl;
  text-orientation: mixed;
  width: 50px;
  height: calc(100% - 16px);
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${props => props.inverted ? 'white' : 'black'};
  border: 2px solid ${props => props.inverted ? 'black' : 'white'};
  color: ${props => props.inverted ? 'black' : 'white'};

  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

  &:active {
  background-color: ${props => props.inverted ? 'black' : 'white'};
  border: 2px solid ${props => props.inverted ? 'white' : 'black'};
  color: ${props => props.inverted ? 'white' : 'black'};
  }
`

const Title = styled.span`
  font-size: 32px;
`

const TitleContainer = styled.div`
  height: 64px;
  width: 100%;
  border-bottom: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`

const ABIInputContainer = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const AddressInput = styled.input`
background-color: black;
border-color: white;
color: white;
font-size: 20px;
  font-family: "Pixelate";
`

const AddAddress = styled.div<{ active: boolean }>`
  padding: 4px;
  border: 1px solid white;
  margin-left: 12px;
  cursor: ${props => props.active ? 'pointer' : 'auto'};
  font-size: 20px;
  opacity: ${props => props.active ? 1 : 0.3};


  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

  ${props => props.active ? `
  &:active {
    background-color: white;
    border: 1px solid black;
    color: black;
  }
  ` : ''};
`

const ABIInput = styled.textarea`
  margin: 0;
  background-color: black;
  border-color: white;
  color: white;
  font-size: 20px;
  font-family: "Pixelate";
  width: 80%;
  height: 30%;
`

const NameInput = styled.input`
  margin: 0;
  background-color: black;
  border-color: white;
  color: white;
  font-size: 20px;
  font-family: "Pixelate";
  margin-top: 12px;
  width: 80%;
`

const AddABI = styled.div<{ active: boolean }>`
  width: 80%;
  padding: 4px;
  border: 1px solid white;
  cursor: ${props => props.active ? 'pointer' : 'auto'};
  font-size: 20px;
  opacity: ${props => props.active ? 1 : 0.3};
  margin-top: 12px;


  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

  ${props => props.active ? `
  &:active {
    background-color: white;
    border: 1px solid black;
    color: black;
  }
  ` : ''};
`

const loadOrDefault = <T extends unknown = any>(initialValue: T, name: string): T => {
  try {
    if (localStorage.getItem(name)) {
      return JSON.parse(localStorage.getItem(name) as string);
    }
    return initialValue;
  } catch (e) {
    return initialValue;
  }
}

const useStorage = <T extends unknown = any>(initialValue: T, name: string): [T, (value: T) => void] => {

  const [value, _setter] = useState(loadOrDefault<T>(initialValue, name));

  const setter = useCallback((v: T) => {
    localStorage.setItem(name, JSON.stringify(v));
    _setter(v);
  }, [_setter, name]);

  return [value, setter];
}

const Address = styled.span<{ selected: boolean }>`
  display: block;
  font-size: 18px;
  margin: 6px;
  cursor: pointer;
  color: ${props => props.selected ? ACTIVE : 'auto'};

  ${props => props.selected ? `
  &:before {
    font-size: 12px;
    content: '→ ';
  }
  `
    : ''
  }
`

const Abi = styled.span<{ selected: boolean }>`
  display: block;
  font-size: 18px;
  margin: 6px;
  cursor: pointer;
  color: ${props => props.selected ? ACTIVE : 'auto'};

  ${props => props.selected ? `
  &:before {
    font-size: 12px;
    content: '→ ';
  }
  `
    : ''
  }
`

interface AbiInfo {
  name: string;
  abi: any[];
}

const Method = styled.span<{ selected: boolean }>`
  display: block;
  font-size: 18px;
  margin: 6px;
  cursor: pointer;
  color: ${props => props.selected ? ACTIVE : 'auto'};


  ${props => props.selected ? `
  &:before {
    font-size: 12px;
    content: '→ ';
  }
  `
    : ''
  }
`

interface Action {
  address: string;
  abi: any;
  methodIdx: number;
  arguments: string[];
  mode: 'query' | 'flash' | 'info';
  gasLimit: string;
  gasPrice: string;
  multiplier: number;
  error: boolean;
  result: any;
  value: string;
}

const ConsoleText = styled.span`
font-size: 20px;
`

const Caret = () => <ConsoleText style={{ fontSize: 20 }}>{'>'}</ConsoleText>

const simulate = async (library: any, account: string, tx: any) => {

  library.provider.isMetaMask = false;
  const block = await library.getBlockNumber();
  const serializedTx = ethers.utils.serializeTransaction(tx);
  const hash = ethers.utils.keccak256(serializedTx);
  let signature;
  try {
    signature = await library.jsonRpcFetchFunc('eth_sign', [account, hash])
  } catch (e) {
    return [200, {
      error: {
        message: 'user denied signature'
      }
    }, serializedTx]
  }
  const serializedAndSignedTx = ethers.utils.serializeTransaction(tx, signature);

  const body = JSON.stringify({
    "jsonrpc": "2.0",
    "id": 1,
    "method": "eth_callBundle",
    "params": [
      {
        txs: [serializedAndSignedTx],
        blockNumber: `0x${(block + 1).toString(16)}`,
        stateBlockNumber: `0x${block.toString(16)}`
      }
    ]
  });

  const res = await fetch('https://fnow.rotaru.fr/api/call_bundle', {
    method: 'POST',
    body: JSON.stringify({
      data: body,
      signature: `${await signer.getAddress()}:${await signer.signMessage(ethers.utils.id(body))}`
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return [res.status, await res.json(), serializedAndSignedTx];
}

const broadcast = async (library: any, serialized: string, block?: number) => {

  if (block === undefined) {
    block = await library.getBlockNumber() + 1;
  }

  const body = JSON.stringify({
    "jsonrpc": "2.0",
    "id": 1,
    "method": "eth_sendBundle",
    "params": [
      {
        txs: [serialized],
        blockNumber: `0x${(block + 1).toString(16)}`,
      }
    ]
  });

  const res = await fetch('https://fnow.rotaru.fr/api/send_bundle', {
    method: 'POST',
    body: JSON.stringify({
      data: body,
      signature: `${await signer.getAddress()}:${await signer.signMessage(ethers.utils.id(body))}`
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return [res.status, await res.json(), block];
}

let fetching = false;

const FlashManager = ({ action, setAction, actions, idx }: { action: Action; setAction: (a: Action) => void; actions: Action[]; idx: number }) => {

  const [flash, setFlash] = useState(true);
  const [fbProvider, setFbProvider] = useState<FlashbotsBundleProvider>(null);
  const { library, account, chainId } = useEthers();
  const [once, setOnce] = useState(true);
  const [everySec, setEverySec] = useState(0);

  useEffect(() => {
    const tid = setTimeout(() => {
      setEverySec(Date.now())
    }, 1000);

    return () => {
      clearTimeout(tid);
    }
  }, [setEverySec])

  const active = useMemo(() => {
    return ([...actions].reverse().findIndex(v => v.mode === 'flash')) === actions.length - (idx + 1)
  }, [actions, idx])

  const methods = useMemo(() => {
    return action.abi.filter((v: any) => v.type === 'function');
  }, [action])

  useEffect(() => {
    setTimeout(() => {
      setFlash(!flash);
    }, 100)
  }, [flash]);

  useEffect(() => {
    if (fbProvider === null && library) {
      FlashbotsBundleProvider.create(
        library as ethers.providers.BaseProvider,
        signer,
        {
          url: 'https://relay.flashbots.net',
          allowInsecureAuthentication: true
        }
      ).then(p => setFbProvider(p))
    }
  }, [fbProvider, library])

  useEffect(() => {
    if (library && active && action.result && action.result.status === 'valid' && !fetching) {
      fetching = true;
      library.getTransactionReceipt(action.result.tx.hash).then(async res => {
        if (res) {
          console.log(`Transaction included !`);
          setAction({
            ...action,
            result: {
              ...action.result,
              status: 'included'
            }
          })
        } else {
          const bn = await library.getBlockNumber();
          const blocks = (action.result.blocks || []).filter(b => b > bn);
          console.log(`Transaction pending inclusion. Current block is ${bn}, bundles broadcasted for ${blocks.join(', ')}`)
          if (blocks.length < 10) {
            broadcast(library, action.result.serializedAndSignedTx, blocks.length ? blocks[blocks.length - 1] + 1 : bn + 1).then(([status, res, block]) => {
              setAction({
                ...action,
                result: {
                  ...action.result,
                  blocks: [...blocks, block]
                }
              })
            })
          }
        }
      }).catch(e => {
        fetching = false;
      }).then(e => {
        fetching = false;
      });
    }
  }, [active, action, library, setAction, everySec])

  useEffect(() => {
    if (active && fbProvider && library && chainId && account && action.result === null && once === true) {
      setOnce(false);
      setTimeout(async () => {
        library.provider.isMetaMask = false;
        const nonce = await library.getTransactionCount(account);
        const contract = new ethers.Contract(action.address, action.abi, library.getSigner(account));
        const tx = await contract.populateTransaction[methods[action.methodIdx].name](...prepareArguments(action.arguments, methods[action.methodIdx].inputs), {
          nonce: nonce,
          gasLimit: ethers.BigNumber.from(action.gasLimit),
          gasPrice: ethers.BigNumber.from(new Decimal(action.gasPrice).mul(action.multiplier).toFixed(0)),
          value: action.value
        });

        tx.chainId = chainId;
        delete tx.from;
        const [status, result, serializedAndSignedTx] = await simulate(library, account, tx);
        if (status === 200) {
          if (result.error) {
            setAction({
              ...action,
              error: true,
              result: {
                status: 'error',
                reason: result.error.message || 'simulation error',
                serializedAndSignedTx,
                tx: ethers.utils.parseTransaction(serializedAndSignedTx),
                blocks: null
              }
            });
          } else if (result.result.coinbaseDiff === '0') {
            setAction({
              ...action,
              error: true,
              result: {
                status: 'error',
                reason: 'transaction probably reverted during simulation',
                serializedAndSignedTx,
                tx: ethers.utils.parseTransaction(serializedAndSignedTx),
                blocks: null
              }
            });
          } else {
            setAction({
              ...action,
              result: {
                status: 'valid',
                serializedAndSignedTx,
                tx: ethers.utils.parseTransaction(serializedAndSignedTx),
                blocks: null
              }
            });
          }
        } else {
          setAction({
            ...action,
            error: true,
            result: {
              status: 'error',
              reason: result.error || 'simulation error',
              serializedAndSignedTx,
              tx: ethers.utils.parseTransaction(serializedAndSignedTx),
              blocks: null
            }
          });
        }

      }, 0);
    }
  }, [fbProvider, action, active, methods, library, chainId, account, setAction, once]);


  // const { library } = useEthers();

  if (action.result === 'aborted by user') {
    return <ConsoleText>aborted by user</ConsoleText>
  }
  if (action.result) {
    switch (action.result.status) {
      case 'valid': {
        if (!active) {
          return <ConsoleText>aborted</ConsoleText>
        }
        return <ConsoleText><span style={{ color: flash ? ACTIVE : 'white' }}>{action.result.tx.hash.slice(0, 12)}</span> GAS={new Decimal(action.gasPrice).mul(action.multiplier).div('1e9').toFixed(3)} [{action.result.blocks?.length ? `${action.result.blocks[0]} + ${action.result.blocks.length - 1}` : ''}] <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => {
          setAction({
            ...action,
            result: 'aborted by user'
          })
        }}>click here to abort</span></ConsoleText>
      }
      case 'error': {
        return <ConsoleText style={{ color: action.error ? 'red' : 'auto' }}>{action.result?.tx?.hash?.slice(0, 12)} {action.result.reason}</ConsoleText>
      }
      case 'included': {
        return <ConsoleText style={{ color: 'green' }}><span onClick={() => {
          window.open(`https://etherscan.io/tx/${action.result.tx.hash}`, '_blank');
        }} style={{ cursor: 'pointer', color: '#32CD32' }}>{action.result.tx.hash.slice(0, 12)}</span> has been included <span style={{ fontSize: 10 }}>{'🎉'}</span></ConsoleText>
      }
      default: {
        return <ConsoleText style={{ color: action.error ? 'red' : 'auto' }}>invalid result payload</ConsoleText>
      }
    }

  } else {
    return <ConsoleText>waiting for signature ...</ConsoleText>
  }

}

const QueryManager = ({ action, setAction }: { action: Action; setAction: (a: Action) => void }) => {

  const { library } = useEthers();

  const methods = useMemo(() => {
    return action.abi.filter((v: any) => v.type === 'function');
  }, [action])

  useEffect(() => {
    if (action.result === null) {
      const contract = new ethers.Contract(action.address, action.abi, library);
      contract.callStatic[methods[action.methodIdx].name](...prepareArguments(action.arguments, methods[action.methodIdx].inputs))
        .then((res: any) => {
          const display = [];
          if (_.isArray(res)) {
            for (const elem of res) {
              display.push(elem.toString());
            }
          } else {
            display.push(res.toString());
          }
          const str = display.join(', ');
          setAction({
            ...action,
            result: str,
            error: false
          });
        })
        .catch((error: any) => {
          setAction({
            ...action,
            result: error.message,
            error: true
          });
        });
    }
  }, [library, action, methods, setAction]);

  if (action.result) {
    return <ConsoleText style={{ color: action.error ? 'red' : 'auto' }}>{action.result}</ConsoleText>
  } else {
    return <ConsoleText>...</ConsoleText>
  }

}

const getFunctionTypeIcon = (type) => {
  switch (type) {
    case 'nonpayable': return '⚙️';
    case 'payable': return '💵';
    default: return '👀'
  }
}

const ConsoleManager = ({ actions, setActions }: { actions: Action[]; setActions: (a: Action[]) => void }) => {

  return <div
    style={{
      width: '100%',
      height: '100%',
      overflow: 'scroll',
      paddingLeft: 6
    }}
  >
    {actions.map((a, idx, arr) =>
      <div key={idx}>
        <Caret /> {
          a.mode === 'query'
            ? <QueryManager key={idx} action={a} setAction={(_a) => {
              actions[idx] = _a;
              setActions([...actions]);
            }} />
            : <FlashManager key={idx} action={a} actions={arr} idx={idx} setAction={(_a) => {
              actions[idx] = _a;
              setActions([...actions]);
            }} />
        }
      </div>
    )}
    <Caret />
    <AlwaysScrollToBottom actions={actions} />
  </div>
}

const AlwaysScrollToBottom = ({ actions }: { actions: Action[] }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  useEffect(() => elementRef.current?.scrollIntoView(), [actions]);
  return <div ref={elementRef} />;
};

const ResetButton = styled.div`
  padding: 6px;
  font-size: 42px;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  cursor: pointer;

  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

  &:active {
  background-color: white;
  border: 2px solid black;
  color: black;
  }
`

const UNIV3_ETH_USDC_3000_POOL = '0x8ad599c3A0ff1De082011EFDDc58f1908eb6e6D8';

const prepareArguments = (providedArguments: string[], inputs: any[]): any[] => {
  const res = [];
  for (let idx = 0; idx < providedArguments.length; ++idx) {
    if (inputs[idx].type.indexOf('[]') === inputs[idx].type.length - 2) {
      res.push(providedArguments[idx].split(','));
    } else {
      res.push(providedArguments[idx]);
    }
  }

  return res;
}

function LogicApp() {

  const { activateBrowserWallet, account, deactivate, library } = useEthers();
  const [validAddress, setValidAddress] = useState(false);
  const [addressInput, setAddressInput] = useStorage<string>('', 'address-input');
  const [addresses, setAddresses] = useStorage<string[]>([], 'addresses');
  const [addressIdx, setAddressIdx] = useStorage<number>(0, 'address-idx');
  const [validAbi, setValidAbi] = useState(false);
  const [abiInput, setAbiInput] = useStorage<string>('', 'abi-input');
  const [abiNameInput, setAbiNameInput] = useStorage<string>('', 'abi-name-input');
  const [abis, setAbis] = useStorage<AbiInfo[]>([], 'abis');
  const [abiIdx, setAbiIdx] = useStorage<number>(0, 'abi-idx');
  const [methodIdx, setMethodIdx] = useStorage<number>(0, 'method-idx');
  const [flashCount, setFlashCount] = useState(0);
  const [flash, setFlash] = useState(false);
  const [flashReady, setFlashReady] = useState(false);
  const [providedArguments, setProvidedArguments] = useStorage<any[]>([], 'provided-arguments');
  const [actions, setActions] = useStorage<Action[]>([], 'actions');
  const history = useHistory();
  const location = useLocation();
  const [gasPrice, setGasPrice] = useStorage<string>('0', 'gas-price');
  const [etherPrice, setEtherPrice] = useStorage<number>(0, 'ether-price');
  const [gasMultiplier, setGasMultiplier] = useStorage<number>(1, 'gas-multiplier');
  const [gasMultiplierInput, setGasMultiplierInput] = useState(gasMultiplier.toString());
  const [gasMultiplierInputValid, setGasMultiplierInputValid] = useState(true);
  const [gasLimitEstimation, setGasLimitEstimation] = useStorage<string>('21000', 'gas-limit');
  const [gasLimitEstimationFinalInput, setGasLimitEstimationFinalInput] = useStorage<string>('21000', 'gas-limit');
  const [gasLimitEstimationInput, setGasLimitEstimationInput] = useStorage<string>('21000', 'gas-limit');
  const [gasLimitEstimationInputValid, setGasLimitEstimationInputValid] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const [txValue, setTxValue] = useStorage<number>(0, 'tx-value');
  const [txValueInput, setTxValueInput] = useState(txValue.toString());
  const [txValueInputValid, setTxValueInputValid] = useState(true);
  const [callError, setCallError] = useState(null);
  const etherBalance = useEtherBalance(account || false);

  const enoughFunds = useMemo(() => {
    if (etherBalance ) {
      return etherBalance.gte(ethers.BigNumber.from(new Decimal(gasPrice).mul(gasLimitEstimationFinalInput).mul(gasMultiplier).add(new Decimal(txValue).mul('1e18')).toFixed(0)))
    }
    return true;
  }, [etherBalance, gasLimitEstimationFinalInput, gasPrice, gasMultiplier, txValue]);

  useEffect(() => {
    setFlashCount(0);
  }, [flashReady, methodIdx]);

  const methods = useMemo(() => {
    if (abis[abiIdx]) {
      return abis[abiIdx].abi.filter(v => v.type === 'function');
    }
    return []
  }, [abis, abiIdx]);

  useEffect(() => {
    setCallError(null);
  }, [methodIdx, abiIdx, addressIdx, providedArguments]);

  useEffect(() => {
    if (methods.length && addresses.length && abis.length && flashReady) {
      const tid = setTimeout(async () => {
        const contract = new ethers.Contract(addresses[addressIdx], abis[abiIdx].abi, library)
        try {
          const estimation = await contract.estimateGas[methods[methodIdx].name](...prepareArguments(providedArguments, methods[methodIdx].inputs), { from: account, value: ethers.BigNumber.from(new Decimal(txValue).mul('1e18').toFixed(0)) });
          setGasLimitEstimationInput(estimation.toString());
          setGasLimitEstimation(estimation.toString())
          setCallError(null);
        } catch (e) {
          setCallError(e?.error?.message || e.message);
          setGasLimitEstimationInput('21000');
        }

      }, 250)
      return () => {
        clearTimeout(tid)
      }
    }
    return () => null;
  }, [methods, methodIdx, addresses, addressIdx, abis, abiIdx, library, providedArguments, flashReady, setGasLimitEstimationInput, setGasLimitEstimation, txValue, account]);

  useEffect(() => {
    try {
      const value = ethers.BigNumber.from(gasLimitEstimationInput)
      setGasLimitEstimationFinalInput(value.toString());
      setGasLimitEstimationInputValid(true);
    } catch (e) {
      setGasLimitEstimationInputValid(false);
    }
  }, [gasLimitEstimationInput, setGasLimitEstimationFinalInput])

  useEffect(() => {
    try {
      const value = parseFloat(gasMultiplierInput)
      if (value === null || isNaN(value)) {
        throw new Error('Number error');
      }
      setGasMultiplier(value);
      setGasMultiplierInputValid(true);
    } catch (e) {
      setGasMultiplierInputValid(false);
    }
  }, [gasMultiplierInput, setGasMultiplier]);

  useEffect(() => {
    try {
      const value = parseFloat(txValueInput)
      if (value === null || isNaN(value)) {
        throw new Error('Number error');
      }
      setTxValue(value);
      setTxValueInputValid(true);
    } catch (e) {
      setTxValueInputValid(false);
    }
  }, [setTxValue, txValueInput, setTxValueInputValid]);

  useEffect(() => {

    if (library) {
      const tid = setTimeout(async () => {
        const feeData = await library.getFeeData();
          setGasPrice(feeData.maxFeePerGas.div(2).toString());
        const univ3 = new ethers.Contract(UNIV3_ETH_USDC_3000_POOL, UniV3PoolAbi, library);
        const slot0 = await univ3.slot0();
        const price = new Decimal(1).div(new Decimal((slot0.sqrtPriceX96.mul(slot0.sqrtPriceX96).mul('1000000') as BigNumber).shr(96 * 2).toString()).div('1e18'));
        setEtherPrice(price.toNumber());
        setLastUpdate(Date.now());
      }, 1000);

      return () => {
        clearTimeout(tid);
      }
    }
    return () => null;

  }, [lastUpdate, library, setGasPrice, setEtherPrice]);

  useEffect(() => {
    history.replace({
      pathname: location.pathname,
      search: '?madeBy=iulian.rotaru'
    })
  }, [history, location.pathname])

  useEffect(() => {
    for (let idx = 0; idx < providedArguments.length; ++idx) {
      if (!providedArguments[idx] || providedArguments[idx] === '') {
        setFlashReady(false);
        return;
      }
    }
    setFlashReady(true);
  }, [providedArguments]);


  useEffect(() => {
    if (methods[methodIdx]) {
      setProvidedArguments(methods[methodIdx].inputs.map((v: any) => ''))
    } else {
      setProvidedArguments([]);
    }
  }, [methodIdx, methods, setProvidedArguments]);

  useEffect(() => {
    setTimeout(() => {
      if (flashCount < 5) {
        setFlash(!flash);
        setFlashCount(flashCount + 1);
      }
    }, 200);
  }, [flash, flashCount]);

  useEffect(() => {
    setValidAddress(ethers.utils.isAddress(addressInput) && !addresses.includes(addressInput.toLowerCase()));
  }, [addressInput, validAddress, addresses]);

  useEffect(() => {
    if (!abiNameInput) {
      setValidAbi(false);
    } else {
      try {
        const abi = JSON.parse(abiInput);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _ = new ethers.utils.Interface(abi);
        setValidAbi(true);
      } catch (e) {
        setValidAbi(false);
      }
    }
  }, [abiInput, abiNameInput, abis]);

  return (
    <AppContainer>
      <ConnectColumn
        inverted={!!account}
        onClick={
          () => {
            if (account) {
              deactivate();
            } else {
              activateBrowserWallet();
            }
          }
        }
      >
        <span
          style={{
            fontSize: 40
          }}
        >
          {
            account || 'CONNECT'
          }
        </span>
      </ConnectColumn>
      <div
        style={{
          width: '50%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          opacity: account ? 1 : 0.2,
          pointerEvents: account ? 'auto' : 'none'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '50%',
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <AddressBookColumn
          >
            <TitleContainer>
              <Title>1. Address Book 📙</Title>
            </TitleContainer>
            <InputContainer>
              <AddressInput
                placeholder={'address'}
                value={addressInput}
                onChange={e => setAddressInput(e.target.value)}
              />
              <AddAddress
                active={validAddress}
                onClick={validAddress ? () => {
                  setAddresses([...addresses, addressInput.toLowerCase()]);
                  setAddressInput('');
                  setAddressIdx(addresses.length);
                } : undefined}
              >
                ADD
              </AddAddress>
            </InputContainer>
            <div style={{
              width: '100%',
              height: '1px',
              backgroundColor: 'white',
              marginTop: 12,
              marginBottom: 12
            }} />
            <div
              style={{
                height: 'calc(100% - 200px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                overflow: 'scroll'
              }}
            >
              {
                addresses.map((v, idx) => <Address
                  key={idx}
                  selected={addressIdx === idx}
                  onClick={() => setAddressIdx(idx)}
                >
                  {v}
                </Address>
                )
              }
            </div>
          </AddressBookColumn>
          <ABIColumn>
            <TitleContainer>
              <Title>2. ABI ⚙️</Title>
            </TitleContainer>
            <ABIInputContainer>
              <ABIInput placeholder={'abi'} value={abiInput} onChange={e => setAbiInput(e.target.value)} />
              <NameInput placeholder={'name'} value={abiNameInput} onChange={e => setAbiNameInput(e.target.value)} />
              <AddABI
                active={validAbi}
                onClick={validAbi ? () => {
                  setAbis([
                    ...abis,
                    {
                      name: abiNameInput,
                      abi: JSON.parse(abiInput)
                    }
                  ]);
                  setAbiInput('');
                  setAbiNameInput('');
                  setAbiIdx(abis.length);
                } : undefined}
              >
                ADD ABI
              </AddABI>
            </ABIInputContainer>
            <div style={{
              width: '100%',
              height: '1px',
              backgroundColor: 'white',
              marginTop: 12,
              marginBottom: 12
            }} />
            <div
              style={{
                height: 'calc(100% - 300px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                overflow: 'scroll'
              }}
            >
              {
                abis.map((v, idx) => <Abi
                  key={idx}
                  selected={abiIdx === idx}
                  onClick={() => {
                    setAbiIdx(idx);
                    setMethodIdx(0);
                  }
                  }
                >
                  {v.name}
                </Abi>
                )
              }
            </div>
          </ABIColumn>
        </div>
        <div
          style={{
            width: '100%',
            height: '50%',
            display: 'flex',
            flexDirection: 'row'
          }}
        >

          <MethodColumn>
            <TitleContainer>
              <Title>3. Method 🧩</Title>
            </TitleContainer>
            <div
              style={{
                height: 'calc(100% - 80px)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                overflow: 'scroll'
              }}
            >
              {
                methods.map((v, idx) => {
                  return <Method key={idx} selected={idx === methodIdx} onClick={() => setMethodIdx(idx)}>
                    <span style={{ fontSize: 12, marginRight: 6 }}>{getFunctionTypeIcon(v.stateMutability)}</span>{' '}{v.name}({v.inputs.length >= 4 ? '...' : v.inputs.map((i: any) => i.type).join(',')})
                  </Method>
                }
                )
              }
            </div>
          </MethodColumn>
        </div>
      </div>
      <TxParametersContainer
        style={{
          opacity: account ? 1 : 0.2,
          pointerEvents: account ? 'auto' : 'none'
        }}
      >
        <ConfigurationRow>
          {
            methods[methodIdx] && addresses[addressIdx] && abis[abiIdx]

              ?
              <>
                <TitleContainer>
                  <Title style={{ fontSize: 20 }}><span style={{ fontSize: 12, marginRight: 12 }}>{getFunctionTypeIcon(methods[methodIdx].stateMutability)}</span>{methods[methodIdx].name}({methods[methodIdx].inputs.length > 4 ? '...' : methods[methodIdx].inputs.map((i: any) => i.type).join(',')})</Title>
                </TitleContainer>
                <div
                  style={{
                    height: methods[methodIdx].inputs.length ? (methods[methodIdx].stateMutability === 'payable' || methods[methodIdx].stateMutability === 'nonpayable' ? '30%' : '100%') : '0%',
                    overflow: 'scroll'
                  }}
                >
                  {
                    methods[methodIdx].inputs.map((i: any, idx: number) =>
                      <div
                        key={idx}
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'row',
                          marginTop: 12
                        }}
                      >
                        <div
                          style={{
                            width: '20%',
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <span style={{ fontSize: 20 }}>{idx + 1}. {i.name}</span>
                          <span>({i.type})</span>
                        </div>
                        <input style={{ width: '70%', backgroundColor: 'black', border: '2px solid white', fontSize: 30, color: 'white', fontFamily: 'Pixelate' }}
                          value={providedArguments[idx]}
                          onChange={(e) => {
                            providedArguments[idx] = e.target.value;
                            setProvidedArguments([...providedArguments]);
                          }}
                        />
                      </div>
                    )
                  }
                </div>
                {
                  methods[methodIdx].inputs.length

                    ? <div
                      style={{
                        height: 1,
                        width: '100%',
                        backgroundColor: 'white',
                        marginTop: 12,
                        marginBottom: 12
                      }}
                    />
                    : null
                }
                {
                  methods.length && (methods[methodIdx].stateMutability === 'payable' || methods[methodIdx].stateMutability === 'nonpayable')

                    ? <>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          flexDirection: 'row',
                          marginTop: 12
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            paddingLeft: 24,
                            paddingRight: 24,
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          <div
                            style={{
                              width: '20%',
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <span style={{ fontSize: 22 }}>Base Fee</span>
                          </div>
                          <span style={{ fontSize: 26, marginLeft: 6 }}>{new Decimal(gasPrice).div('1000000000').toFixed(3)} GWEI</span>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          flexDirection: 'row',
                          marginTop: 12
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            paddingLeft: 24,
                            paddingRight: 24,
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          <div
                            style={{
                              width: '20%',
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <span style={{ fontSize: 22 }}>Multiplier</span>
                          </div>
                          <input
                            onChange={(e) => {
                              setGasMultiplierInput(e.target.value);
                            }}
                            style={{ width: '70%', backgroundColor: 'black', border: '2px solid white', fontSize: 30, color: gasMultiplierInputValid ? 'white' : 'red', fontFamily: 'Pixelate' }} value={gasMultiplierInput} />
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          flexDirection: 'row',
                          marginTop: 12
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            paddingLeft: 24,
                            paddingRight: 24,
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          <div
                            style={{
                              width: '20%',
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <span style={{ fontSize: 22 }}>Gas Limit</span>
                          </div>
                          <input
                            onChange={(e) => {
                              setGasLimitEstimationInput(e.target.value);
                            }}
                            style={{ width: '70%', backgroundColor: 'black', border: '2px solid white', fontSize: 30, color: gasLimitEstimationInputValid && BigNumber.from(gasLimitEstimationFinalInput).gte(gasLimitEstimation) ? 'white' : 'red', fontFamily: 'Pixelate' }} value={gasLimitEstimationInput} />
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          flexDirection: 'row',
                          marginTop: 12
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            paddingLeft: 24,
                            paddingRight: 24,
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          <div
                            style={{
                              width: '20%',
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <span style={{ fontSize: 22 }}>Value (in eth)</span>
                          </div>
                          <input
                            onChange={(e) => {
                              setTxValueInput(e.target.value);
                            }}
                            style={{ width: '70%', backgroundColor: 'black', border: '2px solid white', fontSize: 30, color: txValueInputValid ? 'white' : 'red', fontFamily: 'Pixelate' }} value={txValueInput} />
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          flexDirection: 'row',
                          marginTop: 12
                        }}
                      >
                        <div
                          style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%'
                          }}
                        >
                          <div
                            style={{
                              width: '20%',
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <span style={{ fontSize: 22 }}>Gas Price Total</span>
                          </div>
                          {
                            gasMultiplierInputValid

                              ? <span style={{ fontSize: 26, marginLeft: 6 }}>{new Decimal(gasPrice).mul(gasMultiplier).div('1000000000').toFixed(3)} GWEI</span>

                              : <span style={{ fontSize: 26, marginLeft: 6 }}>???</span>
                          }
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          flexDirection: 'row',
                          marginTop: 12
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            paddingLeft: 24,
                            paddingRight: 24,
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          <div
                            style={{
                              width: '20%',
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <span style={{ fontSize: 22 }}>Total $USD</span>
                          </div>
                          {
                            gasMultiplierInputValid

                              ? <span style={{ fontSize: 26, marginLeft: 6 }}>$ {new Decimal(gasPrice.toString()).mul(gasMultiplier).mul(gasLimitEstimationFinalInput).div('1e18').add(txValue).mul(etherPrice).toFixed(3)} ($ {new Decimal(gasPrice.toString()).mul(gasMultiplier).mul(gasLimitEstimationFinalInput).div('1e18').mul(etherPrice).toFixed(3)} for gas)</span>

                              : <span style={{ fontSize: 26, marginLeft: 6 }}>???</span>
                          }
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          flexDirection: 'row',
                          marginTop: 12
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            paddingLeft: 24,
                            paddingRight: 24,
                            display: 'flex',
                            flexDirection: 'row',
                            color: enoughFunds ? 'white' : 'red'
                          }}
                        >
                          <div
                            style={{
                              width: '20%',
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <span style={{ fontSize: 22 }}>Total ETH</span>
                          </div>
                          {
                            gasMultiplierInputValid

                              ? <span style={{ fontSize: 26, marginLeft: 6 }}><span style={{fontSize: 20}}>Ξ</span> {new Decimal(gasPrice.toString()).mul(gasMultiplier).mul(gasLimitEstimationFinalInput).div('1e18').add(txValue).toFixed(3)} (balance {new Decimal(etherBalance?.toString() || '0').div('1e18').toFixed(3)})</span>

                              : <span style={{ fontSize: 26, marginLeft: 6 }}>???</span>
                          }
                        </div>
                      </div>
                      {
                        callError !== null

                          ? <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              flexDirection: 'row',
                              marginTop: 12,
                              color: 'red'
                            }}
                          >
                            <div
                              style={{
                                width: '100%',
                                paddingLeft: 24,
                                paddingRight: 24,
                                display: 'flex',
                                flexDirection: 'row'
                              }}
                            >

                              <div
                                style={{
                                  width: '20%',
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                <span style={{ fontSize: 22 }}>ERROR</span>
                              </div>
                              <span style={{ fontSize: 26, marginLeft: 6 }}>{callError}</span>
                            </div>
                          </div>

                          : null
                      }
                    </>

                    : null
                }
              </>

              :
              null
          }
        </ConfigurationRow>
        <FlashNow
          onClick={() => {

            if (flashReady && methods.length && enoughFunds) {
              setActions([
                ...actions,
                {
                  address: addresses[addressIdx],
                  abi: abis[abiIdx].abi,
                  methodIdx,
                  arguments: providedArguments,
                  mode: (methods[methodIdx].stateMutability === 'payable' || methods[methodIdx].stateMutability === 'nonpayable') ? 'flash' : 'query',
                  result: null,
                  error: false,
                  gasPrice: gasPrice,
                  gasLimit: gasLimitEstimationFinalInput,
                  multiplier: gasMultiplier,
                  value: new Decimal(txValue).mul('1e18').toFixed(0)
                }
              ])
            }
          }}
          soft={true} flash={false}>
          {
            flashReady && methods.length && enoughFunds

              ?

              (methods[methodIdx].stateMutability === 'payable' || methods[methodIdx].stateMutability === 'nonpayable')

                ?
                <span
                  style={{
                    fontSize: 70
                  }}
                >⚡️⚡️ <span style={{ fontSize: 100 }}>FLASH NOW</span> ⚡️⚡️</span>

                : <span
                  style={{
                    fontSize: 70
                  }}
                >🔍🔍 <span style={{ fontSize: 100 }}>QUERY</span> 🔎🔎</span>

              :
              (enoughFunds && methods[methodIdx]
                ? <span style={{ fontSize: 25 }}> Missing arguments: {methods[methodIdx].inputs.map((v: any, idx: number) => providedArguments[idx] === '' ? idx + 1 : null).filter((v: any) => v !== null).join(', ')}</span>
                : null
              )

          }
        </FlashNow>
        <ConsoleRow>
          <ConsoleManager actions={actions} setActions={setActions} />
        </ConsoleRow>
      </TxParametersContainer>
    </AppContainer>
  );
}

const ResetScreen = () => {

  return <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <div>
      <span
        style={{
          fontSize: 32
        }}
      >An error occured !</span>
      <ResetButton
        onClick={() => {
          localStorage.clear();
          // eslint-disable-next-line no-self-assign
          window.location.href = window.location.href;
        }}
      >
        RESET
      </ResetButton>

    </div>

  </div>
}

export default function App() {
  return <DAppProvider
    config={{
      readOnlyChainId: 1,
      readOnlyUrls: {
        1: 'https://mainnet.infura.io/v3/779d53fb0f2c4a0e95b250ae403fa01e'
      }
    }}
  >
    <Router>
      <ErrorBoundary
        fallback={<ResetScreen />}
      >
        <LogicApp />
      </ErrorBoundary>
    </Router>
  </DAppProvider>
}
